.rewards-spin-game {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    position: relative;

}

.canvas-container {
    border-radius: 50%;
    min-height: 450px;
    overflow: hidden;
    position: relative;
    min-width: 450px;
}

.wheel {
    border-radius: 50%;
    border: 6px solid #FFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    left: 0;
    position: absolute;
    margin: 21px;
    top: 0;
    transition: transform 4s cubic-bezier(.5, -0.08, .5, 1.04);
}

.spin {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: #000000;
    text-transform: uppercase;
    font-size: 20px;
    color: #fff;
    width: 100px;
    height: 100px;
    font-family: sans-serif;
    border-radius: 50%;
    outline: none;
    letter-spacing: 1px;
    cursor: pointer;

}

.arrow {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
