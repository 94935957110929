.container {
    margin: 0 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.fullwidth-container {
    background: linear-gradient(45deg, rgba(220, 29, 104, 0.5) 25%, rgba(68, 208, 209, 0.5) 75%) !important;
}

.fullwidth-container * {
    color: white;
}

.dialog {
    background: linear-gradient(45deg, rgba(220, 29, 104, 0.5) 25%, rgba(68, 208, 209, 0.5) 75%);
}

.dialog * {
    color: white !important;
}

.paper {
    background-color: transparent !important;
    background: linear-gradient(45deg, rgba(220, 29, 104, 0.5) 25%, rgba(68, 208, 209, 0.5) 75%);
    border-radius: 15px !important;
    padding: 1rem;
}

.paper * {
    color: white !important;
}

.wheel-container {
    width: auto;
    min-width: fit-content;
}

.form-control label {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 53%, rgba(255, 255, 255, 0) 40%);
    padding: 0 5px;
}

.form-control .MuiSelect-select {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1rem;
    border-color: white;
    border-width: 0;
    font-size: 1.15em;
 }

.input {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1rem;
    border-color: white;
    border-width: 0;
    font-size: 1.15em;
}

.input-no-padding {
    padding: 0;
}

.input:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.chip {
    background-color: rgba(255, 255, 255, 0.3);
}

.button {
    border-color: white !important;
    color: white !important;
}

.button:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
}

.button-secondary.highlight {
    font-size: 2rem;
    outline: 1px solid rgba(252, 182, 24) !important;
    color: white !important;
    background-color: rgba(252, 182, 24, 1) !important;
}

.button-secondary {
    outline: 1px solid rgba(252, 182, 24) !important;
    color: white !important;
    background-color: rgba(252, 182, 24, .25) !important;
    transition: 0.35s linear;
}

.button-secondary:hover {
    color: white !important;
    background-color: rgba(252, 182, 24, 0.8) !important;
    outline: 1px solid rgba(252, 182, 24) !important;
}

.list-container {
    background-color: rgba(1, 1, 1, 0) !important;
    overflow-y: auto;
    max-height: 270px;
    resize: vertical;
}

.list > li, .list > div {
    background-color: rgba(220, 220, 220, 0.3) !important;
    overflow-y: hidden;
}
