.input input {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 1rem;
    border-color: white;
    border-width: 0;
    font-size: 1em;
}

.input-no-padding input {
    padding: 0;
}

.input input:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.table, .table td, .table thead, .table th, .table tbody, .table table, .table tr {
    background-color: rgba(255,255,255, 0.05) !important;
}

.list-container {
    background-color: rgba(1, 1, 1, 0) !important;
    overflow-y: auto;
    resize: vertical;
}

.list > li, .list > div {
    background-color: rgba(220, 220, 220, 0.3) !important;
    overflow-y: hidden;
}
